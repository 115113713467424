import React from 'react';
import Page from 'components/page';
import Footer from 'components/footer';
import ScrollToAnchor from 'components/scroll/scroll-to-top-anchor';
import { Link } from 'gatsby';
import { CONTACT_US } from 'constants/navigation';

import useStyles from 'apputil/page-styles';

import { Paper, Typography } from '@material-ui/core';

const PrivacyPage = () => {
  const styles = useStyles();

  return (
      <Page
        title="Privacy"
        description="Privacy policy, how we use and manage your data">
        <span id="top-anchor" />
        <Typography variant="h4" component="h1" className={styles.heading}>
          Privacy Policy
        </Typography>
        <Paper className={styles.paper} elevation={4}>
          <Typography variant="h5" component="h2" className={styles.bodyHeader}>
            PRIVACY NOTICE
          </Typography>
          <Typography variant="subtitle1" component="p" className={styles.body1}>
            Last updated Dec 2, 2020
          </Typography>
          <Typography variant="body2" className={styles.body2}>
          {'Thank you for choosing to be part of our community at Total Loyalty (“company”, “we”, “us”, or “our”). We are committed to protecting your personal information and your right to privacy.'}
          <br/>
          {'If you have any questions or concerns about our policy, or our practices with regards to your personal information, please contact us at: '}
            <Link to={CONTACT_US}>http://www.total-loyalty.com/contact-us</Link>
          </Typography>
          <Typography variant="body2" className={styles.body2}>
          {`When you visit our website http://www.total-loyalty.com, and use our services, you trust us with your personal information. We take your privacy very seriously.
            In this privacy notice, we describe our privacy policy. We seek to explain to you in the clearest way possible what information we collect, how we use it and what rights you
            have in relation to it. We hope you take some time to read through it carefully, as it is important. If there are any terms in this privacy policy that you do not agree with, please discontinue use of our Sites and our services.`}
          </Typography>
          <Typography variant="body2" className={styles.body2}>
          {'This privacy policy applies to all information collected through our website (such as http://www.total-loyalty.com), and/or any related services, sales, marketing or events (we refer to them collectively in this privacy policy as the "Sites").'}
          </Typography>
          <Typography variant="body2" className={styles.body2}>
          {'Please read this privacy policy carefully as it will help you make informed decisions about sharing your personal information with us.'}
          </Typography>
          <Typography variant="h6" component="h3" className={styles.bodyHeader}>
            WHAT INFORMATION DO WE COLLECT?
          </Typography>
          <Typography variant="subtitle1" component="p" className={styles.body1}>
            Personal information you disclose to us
          </Typography>
          <Typography variant="body2" className={styles.caption}>
          {'In Short: We collect personal information that you provide to us such as name, address, contact information, passwords and security data, payment information, and social media login data.'}
          </Typography>
          <Typography variant="body2" className={styles.body2Indent}>
          {'Credentials. We collect passwords, password hints, and similar security information used for authentication and account access.'}
          </Typography>
          <Typography variant="body2" className={styles.body2Indent}>
          {'Name and Contact Data. We collect your first and last name, email address, postal address, phone number, and other similar contact data.'}
          </Typography>
          <Typography variant="body2" className={styles.body2Indent}>
          {`Payment Data. We collect data necessary to process your payment if you make purchases, such as your payment instrument number
            (such as a credit card number), and the security code associated with your payment instrument. All payment data is stored by our payment processor and you should
            review its privacy policies and contact the payment processor directly to respond to your questions.`}
          </Typography>
          <Typography variant="body2" className={styles.body2Indent}>
          {`Social Media Login Data. We provide you with the option to register using social media account details, like your Facebook, Twitter or other social media account.
            If you choose to register in this way, we will collect the Information described in the section called "HOW DO WE HANDLE YOUR SOCIAL LOGINS" below.`}
          </Typography>
          <Typography variant="body2" className={styles.body2Indent}>
          {'All personal information that you provide to us must be true, complete and accurate, and you must notify us of any changes to such personal information.'}
          </Typography>
          <Typography variant="subtitle1" component="p" className={styles.body1}>
            Information collected from other sources
          </Typography>
          <Typography variant="body2" className={styles.caption}>
          {'In Short: We may collect limited data from public databases, marketing partners, social media platforms, and other outside sources.'}
          </Typography>
          <Typography variant="body2" className={styles.body2Indent}>
          {`We may obtain information about you from other sources, such as public databases, joint marketing partners, social media platforms
          (such as Facebook), as well as from other third parties. Examples of the information we receive from other sources include: social media profile information (your name, gender, birthday, email, current city, state and country,
            user identification numbers for your contacts, profile picture URL and any other information that you choose to make public); marketing leads and search results and links, including paid listings (such as sponsored links).`}
          </Typography>
          <Typography variant="h6" component="h3" className={styles.bodyHeader}>
            HOW DO WE USE YOUR INFORMATION?
          </Typography>
          <Typography variant="body2" className={styles.caption}>
          {'In Short: We process your information for purposes based on legitimate business interests, the fulfillment of our contract with you, compliance with our legal obligations, and/or your consent.'}
          </Typography>
          <Typography variant="body2" className={styles.body2Indent}>
          {`We use personal information collected via our Sites for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business
            interests ("Business Purposes"), in order to enter into or perform a contract with you ("Contractual"), with your consent ("Consent"), and/or for compliance with our legal obligations ("Legal Reasons").
            We indicate the specific processing grounds we rely on next to each purpose listed below.`}
          </Typography>
          <Typography variant="body2" className={styles.body2Indent}>
          {'We use the information we collect or receive:'}
          </Typography>
          <Typography variant="body2" className={styles.body2Indent}>
          {`To facilitate account creation and logon process.If you choose to link your account with us to a third party account *(such as your Google or Facebook account),
            we use the information you allowed us to collect from those third parties to facilitate account creation and logon process. See the section below headed "HOW DO WE HANDLE YOUR SOCIAL LOGINS"
            for further information.`}
          </Typography>
          <Typography variant="body2" className={styles.body2Indent}>
          {'Fulfill and manage your orders. We may use your information to fulfill and manage your orders, payments, returns, and exchanges made through the Sites.'}
          </Typography>
          <Typography variant="body2" className={styles.body2Indent}>
          {'Request Feedback. We may use your information to request feedback and to contact you about your use of our Sites.'}
          </Typography>
          <Typography variant="body2" className={styles.body2Indent}>
          {'To protect our Sites. We may use your information as part of our efforts to keep our Sites safe and secure (for example, for fraud monitoring and prevention).'}
          </Typography>
          <Typography variant="body2" className={styles.body2Indent}>
          {'To enforce our terms, conditions and policies.'}
          </Typography>
          <Typography variant="body2" className={styles.body2Indent}>
          {'To respond to legal requests and prevent harm. If we receive a subpoena or other legal request, we may need to inspect the data we hold to determine how to respond.'}
          </Typography>
          <Typography variant="body2" className={styles.body2Indent}>
          {`For other Business Purposes. We may use your information for other Business Purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to
            evaluate and improve our Sites, products, services, marketing and your experience. `}
          </Typography>
          <Typography variant="h6" component="h3" className={styles.bodyHeader}>
            WILL YOUR INFORMATION BE SHARED WITH ANYONE?
          </Typography>
          <Typography variant="body2" className={styles.caption}>
          {'In Short: We only share information with your consent, to comply with laws, to protect your rights, or to fulfill business obligations.'}
          </Typography>
          <Typography variant="body2" className={styles.body2Indent}>
          {'We may process or share data based on the following legal basis:'}
          </Typography>
          <Typography variant="body2" className={styles.body2Indent}>
          {'Consent: We may process your data if you have given us specific consent to use your personal information in a specific purpose.'}
          </Typography>
          <Typography variant="body2" className={styles.body2Indent}>
          {'Legitimate Interests: We may process your data when it is reasonably necessary to achieve our legitimate business interests. '}
          </Typography>
          <Typography variant="body2" className={styles.body2Indent}>
          {'Performance of a Contract: Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.'}
          </Typography>
          <Typography variant="body2" className={styles.body2Indent}>
          {`Legal Obligations: We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process,
          such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).`}
          </Typography>
          <Typography variant="body2" className={styles.body2Indent}>
          {`Vital Interests: We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies,
            suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.
          More specifically, we may need to process your data or share your personal information in the following situations:`}
          </Typography>
          <Typography variant="body2" className={styles.body2Indent}>
          {`Vendors, Consultants and Other Third-Party Service Providers. We may share your data with third party vendors, service providers, contractors or agents who perform services for us or on our behalf and require access to such
            information to do that work. Examples include: payment processing, data analysis, email delivery, hosting services, customer service and marketing efforts.
            We may allow selected third parties to use tracking technology on the Sites, which will enable them to collect data about how you interact with the Sites over time.
            This information may be used to, among other things, analyze and track data, determine the popularity of certain content and better understand online activity.
            Unless described in this Policy, we do not share, sell, rent or trade any of your information with third parties for their promotional purposes.`}
          </Typography>
          <Typography variant="body2" className={styles.body2Indent}>
          {'Business Transfers. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.  '}
          </Typography>
          <Typography variant="h6" component="h3" className={styles.bodyHeader}>
            WHO WILL YOUR INFORMATION BE SHARED WITH?
          </Typography>
          <Typography variant="body2" className={styles.caption}>
          {'In Short:  We only share information with the following third parties.'}
          </Typography>
          <Typography variant="body2" className={styles.body2Indent}>
          {'We only share and disclose your information with the following third parties. We have categorized each party so that you may be easily understand the purpose of our data collection and processing practices. If we have processed your data based on your consent and you wish to revoke your consent, please contact us.'}
          </Typography>
          <Typography variant="body2" className={styles.body2Indent}>
          {'Advertising, Direct Marketing, and Lead Generation: Google AdSense'}
          </Typography>
          <Typography variant="body2" className={styles.body2Indent}>
          {'Invoice and Billing: Stripe'}
          </Typography>
          <Typography variant="body2" className={styles.body2Indent}>
          {'User Account Registration and Authentication: Google OAuth 2.0, Google Sign-In, Apple Login and Facebook Login'}
          </Typography>
          <Typography variant="body2" className={styles.body2Indent}>
          {'Web and Mobile Analytics: Google Analytics'}
          </Typography>
          <Typography variant="body2" className={styles.body2Indent}>
          {'Website Performance Monitoring: Bugsnag'}
          </Typography>
          <Typography variant="h6" component="h3" className={styles.bodyHeader}>
            DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
          </Typography>
          <Typography variant="body2" className={styles.caption}>
          {'In Short: We may use cookies and other tracking technologies to collect and store your information.'}
          </Typography>
          <Typography variant="body2" className={styles.body2Indent}>
          {'We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Policy: '}
          <Link to="/cookie-policy">http://www.total-loyalty.com/cookie-policy</Link>
          </Typography>
          <Typography variant="h6" component="h3" className={styles.bodyHeader}>
            HOW DO WE HANDLE YOUR SOCIAL LOGINS?
          </Typography>
          <Typography variant="body2" className={styles.caption}>
          {'In Short: If you choose to register or log in to our websites using a social media account, we may have access to certain information about you.'}
          </Typography>
          <Typography variant="body2" className={styles.body2Indent}>
          {`Our Sites offer you the ability to register and login using your third party social media account details (like your Facebook or Twitter logins).
          Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile Information we receive
          may vary depending on the social media provider concerned, but will often include your name, e-mail address, friends list, profile picture as well as other information
          you choose to make public.`}
          </Typography>
          <Typography variant="body2" className={styles.body2Indent}>
          {`We will use the information we receive only for the purposes that are described in this privacy policy or that are otherwise made clear to you on the Sites.
            Please note that we do not control, and are not responsible for, other uses of your personal information by your third party social media provider.
            We recommend that you review their privacy policy to understand how they collect, use and share your personal information, and how you can set your privacy preferences on
            their sites and apps.`}
          </Typography>
          <Typography variant="h6" component="h3" className={styles.bodyHeader}>
            HOW LONG DO WE KEEP YOUR INFORMATION?
          </Typography>
          <Typography variant="body2" className={styles.caption}>
          {'In Short: We keep your information for as long as necessary to fulfill the purposes outlined in this privacy policy unless otherwise required by law.'}
          </Typography>
          <Typography variant="body2" className={styles.body2Indent}>
          {`We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy policy, unless a longer retention period is required or permitted by law
            (such as tax, accounting or other legal requirements). No purpose in this policy will require us keeping your personal information for longer than 6 months past the termination of the
            user's account.`}
          </Typography>
          <Typography variant="body2" className={styles.body2Indent}>
          {`When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize it, or, if this is not possible
            (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and
            isolate it from any further processing until deletion is possible.`}
          </Typography>
          <Typography variant="h6" component="h3" className={styles.bodyHeader}>
            HOW DO WE KEEP YOUR INFORMATION SAFE?
          </Typography>
          <Typography variant="body2" className={styles.caption}>
          {'In Short: We aim to protect your personal information through a system of organisational and technical security measures.'}
          </Typography>
          <Typography variant="body2" className={styles.body2Indent}>
          {`We have implemented appropriate technical and organisational security measures designed to protect the security of any personal information we process.
            However, please also remember that we cannot guarantee that the internet itself is 100% secure.
            Although we will do our best to protect your personal information, transmission of personal information to and from our Sites is at your own risk.
            You should only access the services within a secure environment. `}
          </Typography>
          <Typography variant="h6" component="h3" className={styles.bodyHeader}>
            DO WE COLLECT INFORMATION FROM MINORS?
          </Typography>
          <Typography variant="body2" className={styles.caption}>
          {'In Short: We do not knowingly collect data from or market to children under 18 years of age.'}
          </Typography>
          <Typography variant="body2" className={styles.body2Indent}>
          {`We do not knowingly solicit data from or market to children under 18 years of age. By using the Sites, you represent that you are at least 18 or that you are the parent or
            guardian of such a minor and consent to such minor dependent’s use of the Sites.
            If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to
            promptly delete such data from our records. If you become aware of any data we have collected from children under age 18, please contact us at `}
            <Link to={CONTACT_US}>http://www.total-loyalty.com/contact-us</Link>
          </Typography>
          <Typography variant="h6" component="h3" className={styles.bodyHeader}>
            WHAT ARE YOUR PRIVACY RIGHTS?
          </Typography>
          <Typography variant="body2" className={styles.caption}>
          {'In Short: In some regions, such as the European Economic Area, you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time.'}
          </Typography>
          <Typography variant="body2" className={styles.body2Indent}>
          {`In some regions (like the European Economic Area), you have certain rights under applicable data protection laws.
            These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable, to data portability.
            In certain circumstances, you may also have the right to object to the processing of your personal information. To make such a request, please use the contact details provided below. We will consider and act upon any request in accordance with applicable data protection laws.`}
          </Typography>
          <Typography variant="body2" className={styles.body2Indent}>
          {'If we are relying on your consent to process your personal information, you have the right to withdraw your consent at any time. Please note however that this will not affect the lawfulness of the processing before its withdrawal.'}
          </Typography>
          <Typography variant="body2" className={styles.body2Indent}>
          {`If you are resident in the European Economic Area and you believe we are unlawfully processing your personal information,
            you also have the right to complain to your local data protection supervisory authority. You can find their contact details here:  `}
            <a href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</a>
          </Typography>
          <Typography variant="subtitle1" component="p" className={styles.body1}>
            Account Information
          </Typography>
          <Typography variant="body2" className={styles.body2Indent}>
          {'If you would at any time like to review or change the information in your account or terminate your account, you can log into your account settings and update your user account.'}
          </Typography>
          <Typography variant="body2" className={styles.body2Indent}>
          {'Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, some information may be retained in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with legal requirements.'}
          </Typography>
          <Typography variant="body2" className={styles.body2Indent}>
          {`Cookies and similar technologies:  Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove
            cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Sites.
            To opt-out of interest-based advertising by advertisers on our Sites visit .`}
            <a href="http://www.aboutads.info/choices/">http://www.aboutads.info/choices/</a>
            {'For further information, please see our Cookie Policy: '}
            <Link to="/cookie-policy">http://www.total-loyalty.com/cookie-policy.</Link>
          </Typography>
          <Typography variant="body2" className={styles.body2Indent}>
          {`Opting out of email marketing: You can unsubscribe from our marketing email list at any time by clicking on the unsubscribe link in the emails that we send or by
            contacting us using the details provided below. You will then be removed from the marketing email list – however,
            we will still need to send you service-related emails that are necessary for the administration and use of your account. To otherwise opt-out, you may access your account settings and update preferences.`}
          </Typography>
          <Typography variant="h6" component="h3" className={styles.bodyHeader}>
            CONTROLS FOR DO-NOT-TRACK FEATURES
          </Typography>
          <Typography variant="body2" className={styles.body2Indent}>
          {`Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (“DNT”)
            feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected.
            No uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to
            DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online.
            If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this Privacy Policy. `}
          </Typography>
          <Typography variant="h6" component="h3" className={styles.bodyHeader}>
            DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
          </Typography>
          <Typography variant="body2" className={styles.caption}>
          {'In Short: Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.'}
          </Typography>
          <Typography variant="body2" className={styles.body2Indent}>
          {`California Civil Code Section 1798.83, also known as the “Shine The Light” law, permits our users who are California residents to request and obtain from us,
            once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes
            and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year.
            If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below. `}
          </Typography>
          <Typography variant="body2" className={styles.body2Indent}>
          {`If you are under 18 years of age, reside in California, and have a registered account with the Sites, you have the right to request removal of unwanted data that you
            publicly post on the Sites. To request removal of such data, please contact us using the contact information provided below,
            and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Sites,
            but please be aware that the data may not be completely or comprehensively removed from our systems.`}
          </Typography>
          <Typography variant="h6" component="h3" className={styles.bodyHeader}>
            DO WE MAKE UPDATES TO THIS POLICY?
          </Typography>
          <Typography variant="body2" className={styles.caption}>
          {'In Short: Yes, we will update this policy as necessary to stay compliant with relevant laws.'}
          </Typography>
          <Typography variant="body2" className={styles.body2Indent}>
          {`We may update this privacy policy from time to time. The updated version will be indicated by an updated “Revised” date and the updated version will be
            effective as soon as it is accessible. If we make material changes to this privacy policy, we may notify you either by prominently posting a notice of
            such changes or by directly sending you a notification. We encourage you to review this privacy policy frequently to be informed of how we are protecting your information. `}
          </Typography>
          <Typography variant="h6" component="h3" className={styles.bodyHeader}>
            HOW CAN YOU CONTACT US ABOUT THIS POLICY?
          </Typography>
          <Typography variant="body2" className={styles.body2MarginBottom}>
          {'If you have questions or comments about this policy, you may email us at '}
            <Link to={CONTACT_US}>http://www.total-loyalty.com/contact-us</Link>
          </Typography>
        </Paper>
        <ScrollToAnchor anchor="#top-anchor" title="Go back to the top of the page" useCalculated/>
        <Footer />
      </Page>
  );
};

export default PrivacyPage;
